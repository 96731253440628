import PropTypes from 'prop-types';
import ButtonApiCall from 'components/ButtonApiCall';
import { useAuth } from 'contexts/auth';
import { useCallback, useState } from 'react';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import { useSearchParams } from 'react-router-dom';

const ButtonApiCallSection = ({
  text,
  route,
  method,
  body,
  redirect_on_submit,
  use_search_params,
  search_params = [],
  to,
  href,
  variant,
  color,
  size,
  font_size,
  full_width,
  circular,
  border_radius,
  icon_only,
  disabled,
  editable,
  onPressEdit,
  ...props
}) => {
  const formattedBorderRadius = Number.isNaN(Number(border_radius)) ? border_radius : Number(border_radius);
  const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
  const [apiResponse, setApiResponse] = useState(null);

  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();

  const callApi = useCallback(() => {
    const copiedSearchParams = new URLSearchParams(searchParams);
    const params = { ...body };

    if (use_search_params) {
      // If search_params array is not empty, filter valid search params
      if (search_params.length > 0) {
        const validSearchParams = search_params.filter((param) => copiedSearchParams.has(param));
        validSearchParams.forEach((param) => {
          const paramValue = copiedSearchParams.get(param);
          if (paramValue !== null) {
            params[param] = paramValue;
          }
        });
      } else {
        // If search_params array is empty, allow all search params
        copiedSearchParams.forEach((paramValue, param) => {
          params[param] = paramValue;
        });
      }
    }

    sendApiRequest(route, method, params, 'bearer')
      .then(({ data }) => {
        setApiResponse(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [route, method, body, setAuth, use_search_params, search_params, searchParams]);

  return (
    <ButtonApiCall
      onClick={callApi}
      redirectOnSubmit={redirect_on_submit}
      to={to}
      href={href}
      variant={variant}
      color={color}
      size={size}
      fontSize={formattedFontSize}
      fullWidth={full_width}
      circular={circular}
      borderRadius={formattedBorderRadius}
      iconOnly={icon_only}
      disabled={disabled}
      {...props}
    >
      {text}
    </ButtonApiCall>
  );
};

ButtonApiCallSection.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  method: PropTypes.oneOf(['put', 'post', 'get', 'delete', 'patch']),
  body: PropTypes.object,
  redirect_on_submit: PropTypes.bool,
  use_search_params: PropTypes.bool,
  search_params: PropTypes.arrayOf(PropTypes.string),
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full_width: PropTypes.bool,
  circular: PropTypes.bool,
  border_radius: PropTypes.string,
  icon_only: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ButtonApiCallSection;
