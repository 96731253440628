import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Image from 'components/Image';
import Modal from 'components/Modal';
import FilePicker from 'components/FilePicker';
import { getFile, createBigFile, updateFile } from 'api/files';
import { handleErrorResponse } from 'utils/general';
import { getFileData } from 'utils/file';
import { withLoading } from 'utils/hoc';
import { useAuth } from 'contexts/auth';

const FileUploaderSection = ({
  file_id,
  input_label,
  button_text,
  button_color,
  button_variant,
  button_size,
  button_font_size,
  onChange,
  max_file_size,
  image_croppable,
  accept_file_types,
  setLoading,
  ...props
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { setAuth } = useAuth();

  const fetchFileFromApi = useCallback(() => {
    if (!file_id) {
      setCurrentFile(null);
      return Promise.resolve();
    }
    setLoading(true);
    const fileParams = {
      $expand: 'original_file',
    };
    return getFile(file_id, fileParams)
      .then(({ data }) => {
        setCurrentFile(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [file_id, setLoading, setAuth]);

  const onUploadFile = useCallback((fileObj, oriFileId, extraData) => {
    setLoading(true);
    return getFileData(fileObj, false)
      .then((fileData) => {
        const fileNamePatternMatch = (fileData.file_name || '').match(/^[0-9A-Za-z\s-._]+$/);
        if (!oriFileId && !fileNamePatternMatch) {
          return Promise.reject(new Error('Invalid File Name\nMake sure file name can contains only A-Z a-z 0-9 - . _'));
        }
        return createBigFile(fileData);
      })
      .then((res) => {
        const { data } = res;
        if (!oriFileId) {
          return res;
        }
        const updateBody = {
          original_file: oriFileId,
          extra_data: JSON.stringify(extraData),
        };
        return updateFile(data.file_id, updateBody);
      })
      .then(({ data }) => {
        setCurrentFile(data);
        return onChange(data.file_id);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onChange, setLoading, setAuth]);

  const deleteFileFromApi = useCallback(() => {
    setCurrentFile(null);
    return onChange('');
  }, [onChange]);

  useEffect(() => {
    fetchFileFromApi();
  }, [fetchFileFromApi]);

  return (
    <MKBox display="flex" flexDirection="column">
      <MKBox display="flex" alignItems="center">
        <MKBox flex={1}>
          <MKInput
            label={input_label}
            value={currentFile?.file_name || ''}
            disabled
            fullWidth
          />
        </MKBox>
        <IconButton
          iconOnly
          disabled={!currentFile}
          onClick={() => setPreviewOpen(true)}
          edge="end"
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          variant="text"
          color="error"
          disabled={!currentFile}
          onClick={deleteFileFromApi}
        >
          <DeleteIcon />
        </IconButton>
      </MKBox>
      <MKBox display="flex" flexDirection="column" pl={1}>
        {!!max_file_size && (
          <MKTypography variant="caption">
            {`Max File Size: ${max_file_size} B`}
          </MKTypography>
        )}
        {accept_file_types?.length > 0 && (
          <MKTypography variant="caption">
            {`Accept File Types: ${(accept_file_types || []).join(', ')}`}
          </MKTypography>
        )}
      </MKBox>
      <MKBox mt={1}>
        <FilePicker
          onAdd={onUploadFile}
          onError={handleErrorResponse}
          buttonText={button_text}
          currentFile={currentFile}
          maxFileSize={max_file_size}
          imageCroppable={image_croppable}
          buttonProps={{
            variant: button_variant,
            color: button_color,
            size: button_size,
            fontSize: button_font_size,
          }}
          accepts={accept_file_types}
          {...props}
        />
      </MKBox>
      <Modal
        showHeader={false}
        isOpen={previewOpen}
        onClose={() => setPreviewOpen(false)}
        p={1}
      >
        <Image
          src={currentFile?.file_url}
          width="100vw"
          style={{ maxWidth: '80vw', maxHeight: '80vh' }}
        />
      </Modal>
    </MKBox>
  );
};

FileUploaderSection.propTypes = {
  file_id: PropTypes.string,
  input_label: PropTypes.string,
  button_text: PropTypes.string,
  button_color: PropTypes.string,
  button_variant: PropTypes.string,
  button_size: PropTypes.string,
  button_font_size: PropTypes.string,
  onChange: PropTypes.func,
  max_file_size: PropTypes.number,
  image_croppable: PropTypes.bool,
  accept_file_types: PropTypes.arrayOf(PropTypes.string),
  setLoading: PropTypes.func,
};

FileUploaderSection.defaultProps = {
  onChange: () => {},
  setLoading: () => {},
};

export default withLoading(FileUploaderSection);
