import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Files from 'react-files';
import Typography from '@mui/material/Typography';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';

import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { uploadAMSFiles } from 'api/ams';

const AMSFileUploaderSection = ({
  fingerprint_database_id,
  audio_match_report_id,
  metadata_id,
  endpoint,
  support_multiple_files,
  onUpload,
  accept_file_types,
  ...props }) => {
  const [currentFiles, setCurrentFiles] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const addFiles = (files) => {
    setCurrentFiles(currentFiles.concat(files));
  };
  const clearFiles = () => {
    currentFiles.length = 0;
    setCurrentFiles([]);
  };
  const startUpload = () => {
    if (!currentFiles.length && !endpoint) {
      return;
    }
    const params = new URLSearchParams({
      ...(fingerprint_database_id && { fingerprint_database_id }),
      ...(audio_match_report_id && { audio_match_report_id }),
      ...(metadata_id && { metadata_id }),
    });
    return uploadAMSFiles(endpoint, currentFiles, params).then((obj) => {
      if (onUpload) {
        return onUpload();
      }
      if (obj && obj.status === 200 && !obj.data.error) {
        alert('Uploaded Successfully');
        navigate(-1);
      } else {
        alert(obj.data?.error);
      }
    }).catch((err) => {
      handleErrorResponse(err, setAuth);
    });
  };

  let labelText = 'No files selected.';
  let popupText = labelText;
  const fileCount = currentFiles.length;
  if (fileCount === 1) {
    const fileName = currentFiles[0].name;
    labelText = fileName;
    popupText = fileName;
  } else {
    labelText = `${fileCount} files selected.`;
    const POPUP_MAX_ROW_COUNT = 25;
    const isWithinMaxRowCount = fileCount <= POPUP_MAX_ROW_COUNT;
    const files = isWithinMaxRowCount ? currentFiles : currentFiles.slice(0, POPUP_MAX_ROW_COUNT);
    popupText = files.map(currentFile => currentFile.file_name).join('\n');
    if (!isWithinMaxRowCount) {
      popupText += '\n...';
    }
  }

  const commonButtonProps = {
    variant: 'text',
    size: 'medium',
    font_size: 16,
    color: '#fff',
    border_radius: '8px',
    sx: {
      backgroundImage: 'url(http://ec2-16-163-40-132.ap-east-1.compute.amazonaws.com/polyu-server/contents/files/Action-Button-bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  };
  return (
    <div>
      <MKBox
        display="flex"
        style={{
          padding: '1rem 2rem',
          fontSize: '16px',
        }}
      >
        <MKBox
          style={{
            marginRight: '4rem',
          }}
        >
          Input files
        </MKBox>
        <Files
          multiple={support_multiple_files}
          accepts={accept_file_types}
          clickable
          onChange={addFiles}
          style={{
            display: 'flex',
          }}
        >
          <MKBox
            style={{
              marginRight: '4rem',
              color: '#000',
            }}
          >
            <Typography
              title={popupText}
              fontSize="16px"
            >
              {labelText}
            </Typography>
          </MKBox>
          <MKBox
            style={{
              marginRight: '4rem',
              color: '#000',
            }}
          >
            <Button
              {...commonButtonProps}
            >
              Add files
            </Button>
          </MKBox>
        </Files>
        {currentFiles.length > 0 && (
          <MKBox
            style={{
              marginRight: '4rem',
              color: '#344767',
            }}
          >
            <Button
              {...commonButtonProps}
              onClick={clearFiles}
            >
              Clear files
            </Button>
          </MKBox>
        )}
        <MKBox
          style={{
            justifySelf: 'end',
            marginLeft: 'auto',
          }}
        >
          <Button
            {...commonButtonProps}
            onClick={startUpload}
          >
            Start
          </Button>
        </MKBox>
      </MKBox>
    </div>
  );
};

AMSFileUploaderSection.propTypes = {
  fingerprint_database_id: PropTypes.string,
  audio_match_report_id: PropTypes.string,
  metadata_id: PropTypes.string,
  endpoint: PropTypes.string,
  support_multiple_files: PropTypes.bool,
  onUpload: PropTypes.func,
  accept_file_types: PropTypes.arrayOf(PropTypes.string),
};

export default AMSFileUploaderSection;
