import qs from 'qs';
import axios from 'axios';
import get from 'lodash/get';
import config from 'config';
import { refreshToken } from 'api/auth';

const baseUrl = config.apiUrl;

const getFullUrl = (url) => {
  return !(/^(http|https):\/\//.test(url)) ? `${baseUrl}${url}` : url;
};

const getOAuthClientCredential = () => ({
  username: config.oauthClientId,
  password: config.oauthClientSecret,
});

export const getStoredAuth = () => {
  let localStorageAuth = null;
  if (localStorage.getItem('auth')) {
    try {
      localStorageAuth = JSON.parse(localStorage.getItem('auth'));
    } catch (err) {
      localStorageAuth = null;
    }
  }
  return localStorageAuth;
};

export const setStoredAuth = (auth) => {
  localStorage.setItem('auth', JSON.stringify(auth));
};

const getRequestConfig = (authType, contentType) => {
  const auth = authType === 'bearer' ? getStoredAuth() : null;
  const token = get(auth, 'access_token');
  return {
    ...(authType === 'basic' ? { auth: getOAuthClientCredential() } : {}),
    headers: {
      'Accept': 'application/json',
      'Content-Type': contentType || 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
};

const apiCallWithRefresh = (apiCall, requestConfig, authType) => {
  return apiCall(requestConfig)
    .catch(async (err) => {
      if (authType === 'basic') {
        throw err;
      }
      const storedAuth = getStoredAuth();
      if (
        get(storedAuth, 'access_token')
        && get(err, 'response.status') === 401
      ) {
        try {
          await refreshToken(get(storedAuth, 'refresh_token'));
        } catch {
          throw err;
        }
        try {
          const newRequestConfig = {
            ...requestConfig,
            ...getRequestConfig(authType),
          };
          return apiCall(newRequestConfig);
        } catch (retryErr) {
          if (get(retryErr, 'response.status') === 401) {
            throw err;
          }
          throw retryErr;
        }
      }
      throw err;
    });
};

const api = {
  get: (url, params, authType) => {
    const fullUrl = getFullUrl(url);
    const requestConfig = {
      ...getRequestConfig(authType),
      paramsSerializer: qs.stringify,
      params,
    };
    const apiGet = (rConfig) => axios.get(fullUrl, rConfig);
    return apiCallWithRefresh(apiGet, requestConfig, authType);
  },
  post: (url, body, authType) => {
    const fullUrl = getFullUrl(url);
    const data = JSON.stringify(body);
    const requestConfig = getRequestConfig(authType);
    const apiPost = (rConfig) => axios.post(fullUrl, data, rConfig);
    return apiCallWithRefresh(apiPost, requestConfig, authType);
  },
  put: (url, body, authType) => {
    const fullUrl = getFullUrl(url);
    const data = JSON.stringify(body);
    const requestConfig = getRequestConfig(authType);
    const apiPut = (rConfig) => axios.put(fullUrl, data, rConfig);
    return apiCallWithRefresh(apiPut, requestConfig, authType);
  },
  delete: (url, authType) => {
    const fullUrl = getFullUrl(url);
    const requestConfig = getRequestConfig(authType);
    const apiDelete = (rConfig) => axios.delete(fullUrl, rConfig);
    return apiCallWithRefresh(apiDelete, requestConfig, authType);
  },
  postUrlFormEncoded: (url, body, authType) => {
    const fullUrl = getFullUrl(url);
    const data = qs.stringify(body);
    const requestConfig = getRequestConfig(authType, 'application/x-www-form-urlencoded');
    const apiPost = (rConfig) => axios.post(fullUrl, data, rConfig);
    return apiCallWithRefresh(apiPost, requestConfig, authType);
  },
  postMultipart: (url, body, authType) => {
    const fullUrl = getFullUrl(url);
    const requestConfig = getRequestConfig(authType, 'multipart/form-data');
    const apiPost = (rConfig) => axios.postForm(fullUrl, body, rConfig);
    return apiCallWithRefresh(apiPost, requestConfig, authType);
  },
};

export default api;
