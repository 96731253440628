import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField, { INPUT_TYPE_SELECT } from 'components/InputField';
import Button from 'components/Button';
import { isValidEmail } from 'utils/form';

const roleOptions = [
  { label: 'User', value: 1 },
  { label: 'Admin', value: 0 },
  { label: 'Manager', value: 2 },
  { label: 'Operator', value: 3 },
  { label: 'Staff', value: 4 },
];

const getFormValidation = (user) => (values) => {
  const { display_name, email, role, password, confirm_password } = values;
  const errors = {};
  if (!display_name) {
    errors.display_name = 'Required';
  }
  if (!email) {
    errors.email = 'Required';
  } else if (!isValidEmail(email)) {
    errors.email = 'Invalid Email';
  }
  if (!role) {
    errors.role = 'Required';
  }
  if (!user) {
    if (!password) {
      errors.password = 'Required';
    }
    if (!confirm_password) {
      errors.confirm_password = 'Required';
    }
    if (password && confirm_password && password !== confirm_password) {
      errors.confirm_password = 'Passwords not match';
      errors.password = 'Passwords not match';
    }
  }
  return errors;
};

const EditUserForm = ({ user, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    display_name: user?.display_name || '',
    email: user?.email || '',
    role: user?.role || 1,
    ...(user ? {} : {
      password: '',
      confirm_password: '',
    }),
  }), [user]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      validate={getFormValidation(user)}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, touched, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <InputField
                  name="display_name"
                  label="Display Name"
                  value={values.display_name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.display_name && !!errors.display_name}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.display_name && !!errors.display_name) ? errors.display_name : '\xa0'}
                </MKTypography>
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="email"
                  label="Email"
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled={!!user}
                  error={touched.email && !!errors.email}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.email && !!errors.email) ? errors.email : '\xa0'}
                </MKTypography>
              </Grid>
              {!user && (
                <Grid container item xs={12}>
                  <InputField
                    name="password"
                    label="Password"
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.password && !!errors.password}
                  />
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {(touched.password && !!errors.password) ? errors.password : '\xa0'}
                  </MKTypography>
                </Grid>
              )}
              {!user && (
                <Grid container item xs={12}>
                  <InputField
                    name="confirm_password"
                    label="Confirm Password"
                    value={values.confirm_password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.confirm_password && !!errors.confirm_password}
                  />
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {(touched.confirm_password && !!errors.confirm_password) ? errors.confirm_password : '\xa0'}
                  </MKTypography>
                </Grid>
              )}
              <Grid container item xs={12}>
                <InputField
                  name="role"
                  label="Role"
                  value={values.role}
                  type={INPUT_TYPE_SELECT}
                  setFieldValue={setFieldValue}
                  options={roleOptions}
                  invalid={touched.role && !!errors.role}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.role && !!errors.role) ? errors.role : '\xa0'}
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditUserForm.propTypes = {
  user: PropTypes.shape({
    display_name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
  }),
  onSave: PropTypes.func,
};

EditUserForm.defaultProps = {
  user: {},
  onSave: () => {},
};

export default EditUserForm;
