import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const DatePicker = ({ label, type, useUnixTimestamp, dateFormat, selectedDate, onDateChange, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(type);
  const value = useUnixTimestamp ? moment.unix(parseInt(paramValue, 10)) : moment(paramValue, dateFormat);
  const defaultSelectedDate = paramValue ? value : moment();

  const internalSelectedDate = useMemo(() => selectedDate || defaultSelectedDate, [
    selectedDate,
    defaultSelectedDate,
  ]);

  const handleDateChange = useCallback(
    (date) => {
      const formattedDate = useUnixTimestamp ? date.unix() : date.format(dateFormat);
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set(type, formattedDate);
        return newParams;
      });
      onDateChange(date);
    },
    [onDateChange, setSearchParams, type, useUnixTimestamp, dateFormat],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        onChange={handleDateChange}
        value={internalSelectedDate}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  useUnixTimestamp: PropTypes.bool,
  dateFormat: PropTypes.string,
  selectedDate: PropTypes.object, // Moment.js object for the selected date
  onDateChange: PropTypes.func, // Callback function for date change
};

export default DatePicker;
