import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NumbersIcon from '@mui/icons-material/Numbers';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import TextInput from 'components/TextInput';
import { getObjectTypes, getPublicObjectTypes } from 'api/object_types';
import { getCollectionDefinition, createCollectionRouting } from 'api/collection_definitions';
import { getDynamicTableRows, setUserObjectCache, createDynamicTableRow, deleteDynamicTableRow, copyDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import { dynamicSort } from 'utils/sort';
import { withLoading } from 'utils/hoc';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getDynamicData, fetchSectionProps } from 'utils/sections';
import { datetimeFormatter, DATE_FORMAT_DATE_TIME_DEFAULT } from 'utils/datetime';
import { useGlobalData } from 'contexts/global-data';
import { useAuth } from 'contexts/auth';

const DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER = '__system_order__';

const DynamicTableListViewSection = ({ collection_definition_id, display_attributes, columns_config, addable, editable, deletable, copyable, type_routing, page_button_color, add_button_label, edit_button_label, delete_button_label, copy_button_label, paste_button_label, disable_title, disable_pagination, section, setLoading, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER);
  const [sortDirection, setSortDirection] = useState('asc');
  const [updatedTypeRouting, setUpdatedTypeRouting] = useState(type_routing || {});
  const { objectTypes, setObjectTypes, copiedDynamicObj, setCopiedDynamicObj } = useGlobalData();
  const { auth, setAuth } = useAuth();

  const fetchObjectTypesFromApi = useCallback(() => {
    const objectTypeParams = {
      $orderBy: 'object_type_id',
    };
    setLoading(true);
    return (auth.user ? getObjectTypes(objectTypeParams) : getPublicObjectTypes(objectTypeParams))
      .then(({ data }) => {
        setObjectTypes(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.user, setObjectTypes, setLoading, setAuth]);

  const fetchDataFromApi = useCallback(() => {
    if (collectionDefinition?.collection_definition_id) {
      setLoading(true);
      return getDynamicTableRows(collectionDefinition.collection_definition_id)
        .then(({ data, headers }) => {
          const contentRange = get(headers, 'content-range');
          const dataCount = Number(contentRange.split('/')[1]);
          setTotalDataCount(dataCount);

          const dynamicFieldValueData = data.map((datum) => ({
            ...getDynamicData(datum, collectionDefinition?.attributes),
            ...datum,
          }));
          const sortedRowData = dynamicFieldValueData.sort(dynamicSort(sortBy || 'createddate', sortDirection));
          setRowData(sortedRowData.slice(
            rowsPerPage * currentPage,
            rowsPerPage * (currentPage + 1),
          ));
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [collectionDefinition, currentPage, rowsPerPage, sortBy, sortDirection, setLoading, setAuth]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      setLoading(true);
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [collection_definition_id, setLoading, setAuth]);

  const onCreateRow = useCallback((objTypeId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const createBody = {
      type: objTypeId,
    };
    setLoading(true);
    return createDynamicTableRow(collectionDefinition.collection_definition_id, createBody)
      .then(({ data }) => {
        if (objTypeId <= 0 && !updatedTypeRouting.default && !(data.type in updatedTypeRouting)) {
          const createRoutingBody = {
            object_type_id: data.type,
            from_section_id: section?.section_id,
          };
          createCollectionRouting(createRoutingBody)
            .then(({ data: newRouting }) => {
              setUpdatedTypeRouting((oriTypeRouting) => ({
                ...oriTypeRouting,
                ...newRouting,
              }));
            })
            .catch((err) => {
              handleErrorResponse(err, setAuth);
            });
        }

        const objectType = objectTypes.find(({ object_type_id }) => object_type_id === Number(data.type));
        if (!objectType) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, section, objectTypes, updatedTypeRouting, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  const onEditRow = useCallback((selectedId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const selectedRow = rowData.find(({ id }) => id === selectedId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    setLoading(true);
    return Promise.all([
      setUserObjectCache(collectionDefinition.collection_definition_id, selectedId),
      (dynamicData?.redirect_collection_definition_id && dynamicData?.redirect_object_id) ? (
        setUserObjectCache(dynamicData.redirect_collection_definition_id, dynamicData.redirect_object_id)
      ) : null,
    ])
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, rowData, setLoading, setAuth]);

  const onSelectRow = useCallback((selectedId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    return Promise.all([
      setUserObjectCache(collectionDefinition.collection_definition_id, selectedId),
    ])
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, setLoading, setAuth]);

  const onDeleteRow = useCallback((datumId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    setLoading(true);
    const selectedRow = rowData.find(({ id }) => id === datumId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    return deleteDynamicTableRow(collectionDefinition.collection_definition_id, datumId)
      .then(() => {
        if (dynamicData?.redirect_collection_definition_id && !dynamicData?.redirect_object_id) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, rowData, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  const onCopyRow = useCallback((datumId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const selectedRow = rowData.find(({ id }) => id === datumId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    return setCopiedDynamicObj({
      id: datumId,
      collection_definition_id: collectionDefinition.collection_definition_id,
      is_collection: dynamicData?.redirect_collection_definition_id && !dynamicData?.redirect_object_id,
    });
  }, [rowData, collectionDefinition, setCopiedDynamicObj]);

  const onPasteRow = useCallback(() => {
    if (!copiedDynamicObj) {
      return Promise.resolve();
    }
    const copyRowBody = {
      from_section_id: section?.section_id,
      destination_collection_definition_id: collectionDefinition?.collection_definition_id,
    };
    setLoading(true);
    return copyDynamicTableRow(copiedDynamicObj.collection_definition_id, copiedDynamicObj.id, copyRowBody)
      .then(({ data }) => {
        if (!updatedTypeRouting.default && !(data.type in updatedTypeRouting)) {
          fetchSectionProps(section, !auth.user)
            .then((updatedSectionProps) => {
              setUpdatedTypeRouting(updatedSectionProps.type_routing);
            })
            .catch((err) => {
              handleErrorResponse(err, setAuth);
            });
        }
        if (copiedDynamicObj.is_collection) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [copiedDynamicObj, collectionDefinition, updatedTypeRouting, section, auth.user, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  const onClickSort = useCallback((headerName) => {
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, []);

  const onUpdateRowValue = useCallback((datumId, attributeId, newValue) => {
    const newValues = {};
    newValues[attributeId] = newValue;
    const updateBody = {
      json_short_data: JSON.stringify(newValues),
    };
    setLoading(true);
    return updateDynamicTableRow(collection_definition_id, datumId, updateBody)
      .then(() => fetchDataFromApi())
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collection_definition_id, fetchDataFromApi, setLoading, setAuth]);

  const renderSystemOrderComponent = useCallback((datum, attribute) => (
    <TextInput
      size="small"
      type="number"
      value={get(datum, attribute.name)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onChange={(e) => {
        onUpdateRowValue(datum.id, attribute.attribute_id, e.target.value);
      }}
    />
  ), [onUpdateRowValue]);

  useEffect(() => {
    setUpdatedTypeRouting(type_routing);
  }, [type_routing]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      fetchDataFromApi();
    }
  }, [collectionDefinition, fetchDataFromApi]);

  const displayAttributes = useMemo(() => {
    if ((display_attributes || []).length > 0) {
      return display_attributes.map((attrName) => {
        const displayAttribute = (collectionDefinition?.attributes || []).find(({ name }) => name === attrName);
        return displayAttribute || { name: attrName };
      });
    }
    return (collectionDefinition?.attributes || []).sort(
      (a1, a2) => a1.sequence - a2.sequence,
    );
  }, [collectionDefinition?.attributes, display_attributes]);

  const columns = useMemo(() => [
    ...displayAttributes.map((attribute) => {
      const { name, data_type } = attribute;
      let dataFormatter;
      switch (data_type) {
        case 1:
          dataFormatter = (v) => (v ? 'Yes' : 'No');
          break;
        case 4:
          dataFormatter = (v) => ((v || '').length > 50 ? `${v.substring(0, 50)}...` : v);
          break;
        case 8:
          dataFormatter = (v) => JSON.stringify(v);
          break;
        default:
          if (['lastmoddate', 'createddate'].includes(name)) {
            dataFormatter = (v) => datetimeFormatter(v, DATE_FORMAT_DATE_TIME_DEFAULT);
          }
          break;
      }
      return {
        field: name,
        ...(name === DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER ? {
          label: <NumbersIcon fontSize="small" />,
          renderContent: (datum) => renderSystemOrderComponent(datum, attribute),
          width: '0',
        } : {}),
        ...(dataFormatter ? {
          formatter: dataFormatter,
        } : {}),
        ...(columns_config && columns_config[name] ? columns_config[name] : {}),
      };
    }),
  ], [displayAttributes, columns_config, renderSystemOrderComponent]);

  const parsedRowData = useMemo(() => rowData.map((rowDatum) => {
    const { json_short_data, json_big_data, ...restDatum } = rowDatum;
    const dynamicData = getDynamicData(rowDatum, collectionDefinition?.attributes);
    return {
      ...restDatum,
      ...dynamicData,
    };
  }), [rowData, collectionDefinition?.attributes]);

  const displayObjectTypes = useMemo(() => (objectTypes || []).filter((objectType) => {
    const { redirect, user_created } = objectType;
    return !user_created;
  }), [objectTypes]);

  // const consoleLogTableInfo = () => {
  //   console.log('DynamicTable');
  //   console.log('columns', columns);
  //   console.log('parsedRowData', parsedRowData);
  //   console.log('displayAttributes', displayAttributes);
  // };

  return (
    <MKBox>
      {!isUuid(collection_definition_id) && (
        <MKTypography color="error">Invalid Collection Definition</MKTypography>
      )}
      {!disable_title && (
        <MKBox display="flex" justifyContent="space-between" alignItems="center" py={1}>
          <MKTypography variant="h5">
            {collectionDefinition?.name}
          </MKTypography>
        </MKBox>
      )}
      {/* <button
        type="submit"
        onClick={consoleLogTableInfo}
      >
        Table information
      </button> */}
      <DataTable
        columns={columns}
        data={parsedRowData}
        selectable={editable || deletable}
        objectTypes={displayObjectTypes}
        typeRouting={updatedTypeRouting}
        onSelectRow={onSelectRow}
        onClickRow={editable ? onEditRow : null}
        onPressAdd={addable ? onCreateRow : null}
        onPressEdit={editable ? onEditRow : null}
        onPressDelete={deletable ? onDeleteRow : null}
        onPressCopy={copyable ? onCopyRow : null}
        onPressPaste={(copyable && !!copiedDynamicObj) ? onPasteRow : null}
        totalCount={totalDataCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={setRowsPerPage}
        paginationDisabled={disable_pagination}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        addButtonLabel={add_button_label}
        editButtonLabel={edit_button_label}
        deleteButtonLabel={delete_button_label}
        copyButtonLabel={copy_button_label}
        pasteButtonLabel={paste_button_label}
        pageButtonProps={{
          color: page_button_color,
        }}
      />
    </MKBox>
  );
};

DynamicTableListViewSection.propTypes = {
  collection_definition_id: PropTypes.string,
  display_attributes: PropTypes.arrayOf(PropTypes.string),
  columns_config: PropTypes.object,
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  copyable: PropTypes.bool,
  type_routing: PropTypes.object,
  page_button_color: PropTypes.string,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  copy_button_label: PropTypes.string,
  paste_button_label: PropTypes.string,
  disable_title: PropTypes.bool,
  disable_pagination: PropTypes.bool,
  section: PropTypes.object,
  setLoading: PropTypes.func,
};

export default withLoading(DynamicTableListViewSection);
