import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser, updateUser, createUser } from 'api/users';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getErrorMessage, handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import EditUserForm from './EditUserForm';

const ResourceNotFound = () => {
  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4>
          Resource not found
        </h4>
      </Container>
    </MKBox>
  );
};

const EditUserSection = () => {
  const [user, setUser] = useState(null);
  const [requestError, setRequestError] = useState(false);
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userId = searchParams.get('username');

  const fetchSolutionFromApi = useCallback(() => {
    if (!userId) {
      return Promise.resolve();
    }
    return getUser(userId)
      .then(({ data }) => {
        setUser(data);
      })
      .catch((error) => {
        setRequestError(true);
        handleErrorResponse(error, setAuth);
      });
  }, [userId, setAuth]);

  const updateUserToApi = useCallback((values, { setFieldError }) => {
    if (!userId) {
      return Promise.resolve();
    }
    const updateBody = {
      display_name: values.display_name,
      role: values.role,
      // email: values.email,
      // test_user: values.test_user,
    };
    return updateUser(userId, updateBody)
      .then(({ data }) => {
        setUser(data);
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [userId, navigate]);

  const createUserToApi = useCallback((values, { setFieldError }) => {
    const createBody = {
      display_name: values.display_name,
      username: values.email,
      email: values.email,
      password: values.password,
      role: values.role,
    };
    return createUser(createBody)
      .then(({ data }) => {
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err));
      });
  }, [navigate]);

  useEffect(() => {
    fetchSolutionFromApi();
  }, [fetchSolutionFromApi]);

  if (requestError) {
    return <ResourceNotFound />;
  }

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: 10 }}>
          {`${userId ? 'Edit' : 'Create'} User`}
        </h4>
        <EditUserForm
          user={user}
          onSave={userId ? updateUserToApi : createUserToApi}
        />
      </Container>
    </MKBox>
  );
};

export default EditUserSection;
