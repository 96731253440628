import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomSelect = ({ options, onChange, values, tag, openToTop, showSelected, fontStyles, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (selectedValue) => {
    const updatedValues = values.includes(selectedValue)
      ? values.filter((value) => value !== selectedValue)
      : [...values, selectedValue];
    onChange(updatedValues);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach event listener when the component mounts
    window.addEventListener('mousedown', handleClickOutside);
    // Detach event listener when the component unmounts
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderLabel = () => {
    const selectedCount = values.length;
    const totalCount = options.length;

    if (!showSelected) {
      const labelContent = values.length > 0 ? `${tag || 'Select'} (${selectedCount}/${totalCount})` : `${tag || 'Select'} (${selectedCount}/${totalCount})`;

      return (
        <MKBox
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {labelContent}
        </MKBox>
      );
    }

    return (
      <MKBox
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {values.join(', ') || tag || 'Select'}
      </MKBox>
    );
  };

  return (
    <MKBox position="relative" ref={dropdownRef}>
      <MKBox
        onClick={handleToggle}
        sx={{
          paddingRight: '32px',
          paddingLeft: '8px',
          position: 'relative',
          ...fontStyles,
        }}
        {...props}
      >
        {renderLabel()}
        <ArrowDropDownIcon
          sx={{
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: `translateY(-50%) rotate(${isOpen ? '180deg' : '0deg'})`,
            transition: 'transform 0.3s ease',
          }}
        />
      </MKBox>
      {isOpen && (
        <ul
          style={{
            position: 'absolute',
            width: 'max-content',
            top: openToTop ? 'auto' : '100%',
            bottom: openToTop ? '100%' : 'auto',
            backgroundColor: '#fff',
            border: '1px solid #000',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
            zIndex: 1,
            listStyleType: 'none',
            padding: '8px',
            margin: 0,
          }}
        >
          {options.map((option) => (
            <li
              key={option.value}
              style={{
                padding: '8px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                checked={values.includes(option.value)}
                onChange={() => handleSelect(option.value)}
                style={{
                  marginRight: '8px',
                }}
              />
              <label
                htmlFor={option.value}
                style={{
                  margin: 0,
                  ...fontStyles,
                }}
              >
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      )}
    </MKBox>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  tag: PropTypes.string,
  fontStyles: PropTypes.object,
  openToTop: PropTypes.bool,
  showSelected: PropTypes.bool,
};

export default CustomSelect;
