import get from 'lodash/get';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AppEditBarSection from 'sections/AppEditBar';
import LayoutEditBarSection from 'sections/LayoutEditBar';
import { getPublicApps } from 'api/apps';
import { getPublicPages } from 'api/pages';
import { getPublicMenu, getPublicMenuItemDependencies } from 'api/menus';
import { useApp } from 'contexts/app';
import { useAuth } from 'contexts/auth';
import { MenuProvider } from 'contexts/menu';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import MKBox from 'components/MaterialKit/MKBox';
import DrawerMenu from 'components/DrawerMenu';
import EditableWrapper from 'components/EditableWrapper';

const UniversalPage = () => {
  const [page, setPage] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sectionsProps, setSectionsProps] = useState({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { app, setApp } = useApp();
  const { auth, setAuth } = useAuth();

  const layout = useMemo(() => get(page, 'layout') || {}, [page]);
  const sections = useMemo(() => get(page, 'sections') || [], [page]);
  const isAuthorMode = useMemo(() => auth.user && (searchParams.get('mode') === 'author'), [auth.user, searchParams]);

  const fetchAppFromApi = useCallback(() => {
    const urlParts = (location.pathname || '').split('/');
    const appBaseUrl = `/${urlParts[1]}`;
    const appParams = {
      base_url: appBaseUrl,
    };
    return getPublicApps(appParams)
      .then(({ data }) => {
        if (data.length) {
          setApp(data[0]);
        } else {
          const retryAppParams = {
            base_url: '/',
          };
          return getPublicApps(retryAppParams)
            .then(({ data: retryData }) => {
              if (retryData.length) {
                setApp(retryData[0]);
              } else {
                setApp(null);
              }
            });
        }
      });
  }, [location.pathname, setApp]);

  const fetchPageFromApi = useCallback(() => {
    const subUrl = location.pathname;
    const appBaseUrl = app?.base_url;
    const pageParams = {
      ...(app ? {
        app: app.app_id,
      } : {}),
      path: subUrl.replace(/\/+$/, '') || '/',
      $expand: 'app,layout/header/section_definition/collection_definition/attributes,layout/footer/section_definition/collection_definition/attributes,sections/section_definition/collection_definition/attributes',
      $orderBy: 'createddate desc',
    };
    return getPublicPages(pageParams)
      .then(({ data }) => {
        if (!data.length) {
          setPage(null);
        } else if ((appBaseUrl !== '/' ? `${appBaseUrl}${subUrl}` : subUrl).replace(/\/+$/, '') === window.location.pathname.replace(/\/+$/, '')) {
          setPage(data[0]);
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [location.pathname, app, setAuth]);

  const fetchSectionDataFromApi = useCallback((section) => {
    const { section_id } = section;
    return fetchSectionProps(section, !auth.user)
      .then((sectionProps) => {
        setSectionsProps((oriSectionsProps) => {
          const updatedSectionsProps = { ...oriSectionsProps };
          updatedSectionsProps[section_id] = sectionProps;
          return updatedSectionsProps;
        });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth.user, setAuth]);

  const fetchMenuItemsFromApi = useCallback((menuNodeId) => {
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $expand: 'menu_item',
      $orderBy: 'sequence',
    };
    return getPublicMenuItemDependencies(menuItemDependencyParams)
      .then(({ data: menuItemDependencies }) => {
        const menuItems = (menuItemDependencies || []).map(({ menu_item }) => menu_item);
        return menuItems;
      });
  }, []);

  const fetchRecursiveMenuItemsFromApi = useCallback(async (menuNodeId) => {
    const menuItems = await fetchMenuItemsFromApi(menuNodeId);
    const updatedMenuItems = await Promise.all(menuItems.map(async (menuItem) => {
      const { menu_item_id, type } = menuItem;
      if (type === 1) {
        const menuSubItems = await fetchRecursiveMenuItemsFromApi(menu_item_id);
        return {
          ...menuItem,
          menu_items: menuSubItems,
        };
      }
      return menuItem;
    }));
    return updatedMenuItems;
  }, [fetchMenuItemsFromApi]);

  const fetchMenuFromApi = useCallback(() => {
    if (layout?.menu) {
      const menuParams = {
        $expand: 'menu_type,menu_node',
      };
      return getPublicMenu(layout.menu, menuParams)
        .then(async ({ data }) => {
          const { menu_node } = data;
          if (menu_node?.type === 1) {
            const aggregatedMenuItems = await fetchRecursiveMenuItemsFromApi(menu_node?.menu_item_id);
            setMenu({
              ...data,
              menu_node: {
                ...menu_node,
                menu_items: aggregatedMenuItems,
              },
            });
          }
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
    setMenu(null);
  }, [layout.menu, fetchRecursiveMenuItemsFromApi, setAuth]);

  const onPressEditSection = useCallback((sectionId) => {
    navigate(`/section-attributes/${sectionId}?from=${location.pathname}`);
  }, [location.pathname, navigate]);

  const onPressEditApp = useCallback(() => {
    window.location.assign(`/app/${app?.app_id}`);
  }, [app]);

  const onPressEditPage = useCallback(() => {
    window.location.assign(`/app/${app?.app_id}/page/${page?.page_id}`);
  }, [page, app]);

  const onPressEditMenu = useCallback((menuId) => {
    window.location.assign(`/app/${app?.app_id}/menu/${menuId}`);
  }, [app]);

  useEffect(() => {
    if (searchParams.get('mode') === 'author' && !auth.user) {
      navigate(`/login?redirect=${location.pathname}${location.search}`);
    }
  }, [auth.user, location, searchParams, navigate]);

  useEffect(() => {
    if (app && auth.required) {
      setAuth((oriAuth) => ({
        ...oriAuth,
        required: false,
      }));
      if (location.pathname !== '/login') {
        navigate(`/login?redirect=${location.pathname}${location.search}`);
      }
    }
  }, [app, location, auth.required, navigate, setAuth]);

  useEffect(() => {
    const totalSections = [
      ...(layout?.header ? [layout.header] : []),
      ...(layout?.footer ? [layout.footer] : []),
      ...sections,
    ];
    (totalSections || []).forEach((section) => {
      fetchSectionDataFromApi(section);
    });
  }, [layout, sections, fetchSectionDataFromApi]);

  useEffect(() => {
    fetchMenuFromApi();
  }, [fetchMenuFromApi]);

  useEffect(() => {
    if (app) {
      fetchPageFromApi();
    } else {
      fetchAppFromApi();
    }
  }, [app, fetchAppFromApi, fetchPageFromApi]);

  useEffect(() => {
    if (!isAuthorMode && (typeof page?.refresh_rate === 'number') && page?.refresh_rate > 0) {
      const numberOfSec = (page?.refresh_rate || 0) * 1000;
      const intervalTimerId = setInterval(() => {
        fetchPageFromApi();
      }, numberOfSec);
      return () => {
        clearInterval(intervalTimerId);
      };
    }
  }, [fetchPageFromApi, isAuthorMode, page?.refresh_rate]);

  return page ? (
    <MKBox display="flex" flexDirection="column" sx={{ height: '100%' }}>
      {isAuthorMode && (
        <MKBox bgColor="light" shadow="md" sx={{ zIndex: 1 }}>
          <EditableWrapper editable onPressEdit={onPressEditApp}>
            <AppEditBarSection app={app} />
          </EditableWrapper>
          <EditableWrapper editable onPressEdit={onPressEditPage}>
            <LayoutEditBarSection layout={page?.layout} />
          </EditableWrapper>
        </MKBox>
      )}
      <MenuProvider value={{ menu, setMenu }}>
        <MKBox
          flex={1}
          display="flex"
          sx={{
            backgroundImage: `url(${page?.background_image_url})`,
            backgroundColor: page?.background_color,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          {['sidebar', 'drawer'].includes(menu?.menu_type?.name) && (
            <EditableWrapper
              editable={isAuthorMode}
              onPressEdit={() => onPressEditMenu(menu?.menu_id)}
              sx={{
                '.MuiDrawer-paper': {
                  position: 'relative',
                },
              }}
            >
              <DrawerMenu menu={menu} />
            </EditableWrapper>
          )}
          <MKBox display="flex" flexDirection="column" flex={1} sx={{ overflow: 'auto' }}>
            {renderSectionComponent(layout?.header, sectionsProps[layout?.header?.section_id], isAuthorMode, onPressEditSection)}
            <MKBox flex={1}>
              {sections.sort((s1, s2) => s1.sequence - s2.sequence).map((section, idx) => {
                return (
                  <Fragment key={`${section.section_id}-${idx}`}>
                    {renderSectionComponent(section, sectionsProps[section.section_id], isAuthorMode, onPressEditSection)}
                  </Fragment>
                );
              })}
            </MKBox>
            {renderSectionComponent(layout?.footer, sectionsProps[layout?.footer?.section_id], isAuthorMode, onPressEditSection)}
          </MKBox>
        </MKBox>
      </MenuProvider>
    </MKBox>
  ) : null;
};

export default UniversalPage;
