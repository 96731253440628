import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './custom-editor.css';

const RichTextEditor = ({ value, onChange, readOnly, placeholder, ...props }) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      defaultValue=""
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      modules={{
        toolbar: [
          [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', { color: [] }, { background: [] }],
          ['blockquote', 'code', { align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link'],
          ['clean'],
        ],
      }}
      formats={[
        'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'color', 'background',
        'blockquote', 'code', 'align',
        'list', 'bullet', 'indent',
        'link',
      ]}
      {...props}
    />
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
};

RichTextEditor.defaultProps = {
  value: '',
  onChange: () => {},
  readOnly: false,
  placeholder: null,
};

export default RichTextEditor;
