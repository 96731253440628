import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ImageCropper from './ImageCropper';

const FilePicker = ({ onAdd, onError, buttonText, currentFile, buttonProps, accepts, maxFiles, maxFileSize, minFileSize, imageCroppable, ...props }) => {
  const [cropperOpen, setCropperOpen] = useState(false);

  const onChange = useCallback((files) => {
    if (files && files.length > 0) {
      const file = files[files.length - 1];
      return onAdd(file);
    }
    return Promise.resolve();
  }, [onAdd]);

  const onToggleCropper = useCallback(() => {
    setCropperOpen((isOpen) => !isOpen);
  }, []);

  const onUpdateCroppedImage = useCallback((file, cropData) => {
    const originalFileId = currentFile.original_file?.file_id || currentFile?.file_id;
    onAdd(file, originalFileId, cropData);
    setCropperOpen(false);
  }, [currentFile, onAdd]);

  const cropData = useMemo(() => {
    try {
      return JSON.parse(currentFile?.extra_data);
    } catch (err) {
      return {};
    }
  }, [currentFile]);

  return (
    <MKBox display="flex" flexDirection="row">
      <Button {...buttonProps}>
        {buttonText}
        <Files
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          onChange={onChange}
          onError={onError}
          accepts={accepts}
          nultiple={maxFiles > 1}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize}
          minFileSize={minFileSize}
          {...props}
        />
      </Button>
      {imageCroppable && !!currentFile && (
        <MKBox sx={{ ml: 1 }}>
          <Button onClick={onToggleCropper} {...buttonProps}>
            Crop Image
          </Button>
          <Modal title="Crop Image" isOpen={cropperOpen} onClose={() => setCropperOpen(false)}>
            <MKBox p={2}>
              <ImageCropper
                url={(currentFile?.original_file || currentFile)?.file_url}
                name={currentFile.file_name}
                cropData={cropData}
                onConfirm={onUpdateCroppedImage}
                onCancel={() => setCropperOpen(false)}
              />
            </MKBox>
          </Modal>
        </MKBox>
      )}
    </MKBox>
  );
};

FilePicker.propTypes = {
  onAdd: PropTypes.func,
  onError: PropTypes.func,
  buttonText: PropTypes.string,
  currentFile: PropTypes.any,
  buttonProps: PropTypes.object,
  accepts: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  minFileSize: PropTypes.number,
  imageCroppable: PropTypes.bool,
};

FilePicker.defaultProps = {
  onAdd: () => { },
  onError: () => { },
  buttonText: 'Upload File',
  buttonProps: {},
  currentFile: null,
  accepts: ['*/*'],
  maxFiles: 1,
  maxFileSize: Infinity,
  minFileSize: 0,
  imageCroppable: false,
};

export default FilePicker;
