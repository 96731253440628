import { createRef, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import mime from 'mime';
import Cropper from 'react-cropper';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import 'cropperjs/dist/cropper.css';
import './cropper.css';

const ImageCropper = ({ url, name, cropData, onConfirm, onCancel }) => {
  const [dimension, setDimension] = useState(null);
  const cropperRef = createRef(null);

  const getCropData = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const croppedImageExt = mime.getExtension(blob.type);
        const fileNameNoExt = (name || '').replace(/\.\w+$/, '');
        const newFilename = `${fileNameNoExt}.${croppedImageExt}`;
        const file = new File([blob], newFilename, { type: blob.type, lastModified: new Date().getTime() });
        const preview = { type: 'image', url: blobUrl };
        file.preview = preview;
        onConfirm(file, cropper.getData());
      });
    }
  }, [cropperRef, name, onConfirm]);

  const onCropMove = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const { width, height } = cropper.getCroppedCanvas();
      setDimension({ width, height });
    }
  }, [cropperRef]);

  return (
    <MKBox display="flex" flexDirection="column">
      <MKBox>
        <Cropper
          ref={cropperRef}
          style={{ height: '45vh', width: '100%' }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={url}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          cropmove={onCropMove}
          data={cropData}
          guides
        />
      </MKBox>
      <MKBox>
        <MKTypography>Preview</MKTypography>
        <MKBox
          className="img-preview"
          sx={{ width: '100%', height: '35vh', border: '1px solid black' }}
        />
        {!!dimension && (
          <MKTypography variant="body2">{`${dimension?.height || 0}px x ${dimension?.width || 0}px`}</MKTypography>
        )}
      </MKBox>
      <MKBox display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button sx={{ ml: 1 }} onClick={getCropData}>
          Confirm
        </Button>
      </MKBox>
    </MKBox>
  );
};

ImageCropper.propTypes = {
  url: PropTypes.string,
  cropData: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  name: PropTypes.string,
};

ImageCropper.defaultProps = {
  url: null,
  cropData: {},
  onConfirm: () => {},
  onCancel: () => {},
  name: null,
};

export default ImageCropper;
