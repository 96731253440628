import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { datetimeFormatter } from 'utils/datetime';
import DatePicker from 'components/DatePicker';
import moment from 'moment';

const Logs = ({
  logTitle,
  exportButtonTitle,
  exportButtonProps,
  useDateFilter,
  useErrorFilter,
  useUserFilter,
  exportable,
  extensions,
  sortBy,
  content,
  fontSize,
  ...props
}) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [errorFilter, setErrorFilter] = useState(null);

  useEffect(() => {
    const parseDateFromSearchParams = (paramName) => {
      const searchParams = new URLSearchParams(window.location.search);
      const dateString = searchParams.get(paramName);
      return dateString ? moment(dateString) : null;
    };

    const fromDateParam = parseDateFromSearchParams('from');
    const toDateParam = parseDateFromSearchParams('to');

    if (!fromDateParam) {
      const currentFrom = moment();
      setFromDate(currentFrom);
      const formattedFrom = currentFrom.format('YYYY-MM-DD');
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('from', formattedFrom);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    } else {
      setFromDate(fromDateParam);
    }
    if (!toDateParam) {
      const currentTo = moment().add(1, 'year');
      setToDate(currentTo);
      const formattedTo = currentTo.format('YYYY-MM-DD');
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('to', formattedTo);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    } else {
      setToDate(toDateParam);
    }
  }, []);

  const filteredContent = useMemo(() => {
    return content.filter((datum) => {
      const datumDate = moment(datum.createddate);
      let ret = true;
      if (useDateFilter) {
        ret = ret && (!fromDate || datumDate.isSameOrAfter(fromDate.startOf('day'))) && (!toDate || datumDate.isSameOrBefore(toDate.endOf('day')));
      }
      if (useUserFilter) {
        ret = ret && (!userFilter || datum.username === userFilter);
      }
      if (useErrorFilter) {
        ret = ret && (!errorFilter || datum.errorType === errorFilter);
      }
      return ret;
    });
  }, [content, useDateFilter, fromDate, toDate, useUserFilter, userFilter, useErrorFilter, errorFilter]);

  const handleUserInputChange = (event) => {
    setUserFilter(event.target.value);
  };

  const handleErrorInputChange = (event) => {
    setErrorFilter(event.target.value);
  };

  const handleCsvExport = () => {
    // ==Todo==
    console.log('Export CSV');
  };

  const handlePdfExport = () => {
    // ==Todo==
    console.log('Export PDF');
  };

  const handleExportButtonClick = (name) => {
    // ==Todo==
    switch (name) {
      case 'csv':
        handleCsvExport();
        break;
      case 'pdf':
        handlePdfExport();
        break;
      default:
        break;
    }
  };

  const exportButtons = () => {
    return (
      <MKBox
        display="flex"
        justifyContent="end"
        width="50%"
        alignItems="center"
      >
        {extensions && extensions.map((extension, index) => {
          return (
            <Button
              key={index}
              variant="text"
              size="medium"
              fontSize="16px"
              fullWidth
              onClick={() => handleExportButtonClick(extension)}
              iconOnly={false}
              disabled={false}
              circular={false}
              {...exportButtonProps}
            >
              {`${exportButtonTitle} (${extension.toUpperCase()})`}
            </Button>
          );
        })}
      </MKBox>
    );
  };

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      {...props}
    >
      {/* Title and Button section */}
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="auto"
        width="100%"
        py="0.5rem"
      >
        <MKTypography variant="h6">{`${logTitle} (${(filteredContent || []).length}) `}</MKTypography>
        {exportButtons()}
      </MKBox>

      {/* Controls section */}
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="auto"
        width="100%"
        py="1rem"
      >
        {useUserFilter && (
          <MKBox
            display="flex"
            flex="1"
            justifyContent="start"
            alignItems="center"
          >
            <MKTypography variant="h6" sx={{ marginRight: 1 }}>User: </MKTypography>
            <input
              type="text"
              value={userFilter || ''}
              onChange={handleUserInputChange}
            />
          </MKBox>
        )}
        {useDateFilter && (
          <MKBox
            display="flex"
            flex="2"
            justifyContent={useErrorFilter ? 'start' : 'end'}
            alignItems="center"
          >
            <MKBox
              display="flex"
              alignItems="center"
            >
              <MKTypography variant="h6" sx={{ marginRight: 1 }}>From: </MKTypography>
              <DatePicker
                type="from"
                dateFormat="YYYY-MM-DD"
                useUnixTimestamp={false}
                selectedDate={fromDate}
                onDateChange={setFromDate}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '50px',
                  minWidth: '150px',
                }}
              />
            </MKBox>
            <MKBox
              display="flex"
              alignItems="center"
            >
              <MKTypography variant="h6" sx={{ marginX: 1 }}>To: </MKTypography>
              <DatePicker
                type="to"
                dateFormat="YYYY-MM-DD"
                useUnixTimestamp={false}
                selectedDate={toDate}
                onDateChange={setToDate}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '50px',
                  minWidth: '150px',
                }}
              />
            </MKBox>
          </MKBox>
        )}
        {useErrorFilter && (
          <MKBox
            display="flex"
            flex="1"
            justifyContent="end"
            alignItems="center"
          >
            <MKTypography variant="h6" sx={{ marginRight: 1 }}>Error Type: </MKTypography>
            <input
              type="text"
              value={errorFilter || ''}
              onChange={handleErrorInputChange}
            />
          </MKBox>
        )}
      </MKBox>
      {/* Text Area Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="left"
        paddingX="0.5rem"
        paddingY="0.25rem"
        height="50vh"
        width="100%"
        sx={{
          border: '1px solid #000',
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        {Array.isArray(filteredContent) && filteredContent.length > 0 && (
          filteredContent.map((datum, index) => (
            <MKTypography key={index} fontSize={fontSize}>
              {datum.createddate ? datetimeFormatter(datum.createddate, 'YYYY-MM-DD, HH:mm:ss') : 'Unknown Date'}
              {' ('}
              {datum.display_name || 'Unknown User'}
              {'): '}
              {datum.description || 'Unknown Action'}
            </MKTypography>
          )))}
        {Array.isArray(filteredContent) && filteredContent.length === 0 && <MKTypography fontSize={fontSize}>No logs found.</MKTypography>}

      </MKBox>
    </MKBox>
  );
};

Logs.propTypes = {
  logTitle: PropTypes.string,
  exportButtonTitle: PropTypes.string,
  exportButtonProps: PropTypes.object,
  useDateFilter: PropTypes.bool,
  useErrorFilter: PropTypes.bool,
  useUserFilter: PropTypes.bool,
  exportable: PropTypes.bool,
  extensions: PropTypes.array,
  sortBy: PropTypes.string,
  content: PropTypes.array,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Logs.defaultProps = {
  logTitle: null,
  exportButtonTitle: null,
  exportButtonProps: null,
  useDateFilter: null,
  useErrorFilter: null,
  useUserFilter: null,
  exportable: null,
  extensions: null,
  sortBy: null,
  content: [],
  fontSize: null,
};

export default Logs;
