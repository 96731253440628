import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import AnchorMenu from 'components/AnchorMenu';
import InputField, { INPUT_TYPE_BOOLEAN, INPUT_TYPE_DATETIME, INPUT_TYPE_NUMBER, INPUT_TYPE_TEXTAREA, INPUT_TYPE_FILE, INPUT_TYPE_IMAGE, INPUT_TYPE_VIDEO, INPUT_TYPE_AUDIO, INPUT_TYPE_TEXT, INPUT_TYPE_RICH_TEXT } from 'components/InputField';
import { getCollectionDefinition, createCollectionRouting } from 'api/collection_definitions';
import { getDynamicTableRows, setUserObjectCache, updateDynamicTableRow, createDynamicTableRow, deleteDynamicTableRow, copyDynamicTableRow } from 'api/sections';
import { getObjectTypes, getPublicObjectTypes } from 'api/object_types';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getDynamicData, fetchSectionProps } from 'utils/sections';
import { withLoading } from 'utils/hoc';
import { useGlobalData } from 'contexts/global-data';
import { useAuth } from 'contexts/auth';

const mandatoryAttributeNames = ['key', 'description'];

const parseData = (data, attributes) => {
  return data.map((datum) => {
    const { json_short_data, json_big_data, ...restDatum } = datum;
    const dynamicData = getDynamicData(datum, attributes);
    return {
      ...restDatum,
      ...dynamicData,
    };
  });
};

const getFormValues = (parsedRowData, objectTypes) => {
  return parsedRowData.reduce((values, parsedRowDatum) => {
    const newValues = { ...values };
    const objectType = objectTypes.find(({ object_type_id }) => object_type_id === Number(parsedRowDatum.type));
    let parsedValue = get(parsedRowDatum, 'value');
    if ([3, 4, 15, 16].includes(objectType?.object_type_id)) {
      parsedValue = Number(parsedValue);
    }
    newValues[`${parsedRowDatum.id}-name`] = get(parsedRowDatum, 'name');
    newValues[`${parsedRowDatum.id}-value`] = parsedValue;
    newValues[`${parsedRowDatum.id}-__system_order__`] = Number(get(parsedRowDatum, '__system_order__'));
    mandatoryAttributeNames.forEach((attributeName) => {
      newValues[`${parsedRowDatum.id}-${attributeName}`] = get(parsedRowDatum, attributeName);
    });
    return newValues;
  }, {});
};

const DynamicTableAttributeViewSection = ({ collection_definition_id, addable, deletable, copyable, type_routing, fields_config, add_button_label, delete_button_label, copy_button_label, paste_button_label, disable_title, section, setLoading, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [updatedTypeRouting, setUpdatedTypeRouting] = useState(type_routing || {});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [formInitialized, setFormInitialized] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const { objectTypes, setObjectTypes, copiedDynamicObj, setCopiedDynamicObj } = useGlobalData();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const nameAttribute = useMemo(() => (collectionDefinition?.attributes || []).find(({ name }) => name === 'name'), [collectionDefinition?.attributes]);
  const valueAttribute = useMemo(() => (collectionDefinition?.attributes || []).find(({ name }) => name === 'value'), [collectionDefinition?.attributes]);
  const systemOrderAttribute = useMemo(() => (collectionDefinition?.attributes || []).find(({ name }) => name === '__system_order__'), [collectionDefinition?.attributes]);

  const onOpenMenu = useCallback(({ currentTarget }) => setMenuAnchor(currentTarget), []);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const fetchObjectTypesFromApi = useCallback(() => {
    const objectTypeParams = {
      $orderBy: 'object_type_id',
    };
    setLoading(true);
    return (auth.user ? getObjectTypes(objectTypeParams) : getPublicObjectTypes(objectTypeParams))
      .then(({ data }) => {
        setObjectTypes(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.user, setObjectTypes, setLoading, setAuth]);

  const updateFormValues = useCallback((data) => {
    if (formRef.current) {
      const updatedParsedRowData = parseData(data, collectionDefinition?.attributes);
      const updatedFormValues = getFormValues(updatedParsedRowData, objectTypes);
      formRef.current.setValues((oriValues) => {
        return Object.keys(updatedFormValues).reduce((values, field) => {
          const updatedValues = { ...values };
          if (field in oriValues) {
            updatedValues[field] = oriValues[field];
          } else {
            updatedValues[field] = updatedFormValues[field];
          }
          return updatedValues;
        }, {});
      });
    }
  }, [collectionDefinition?.attributes, objectTypes]);

  const fetchDataFromApi = useCallback((update) => {
    if (collectionDefinition?.collection_definition_id) {
      const dynamicTableParams = {
        $orderBy: 'createddate asc',
      };
      setLoading(true);
      return getDynamicTableRows(collectionDefinition.collection_definition_id, dynamicTableParams)
        .then(({ data }) => {
          setRowData(data);
          setDataLoaded(true);
          if (update) {
            updateFormValues(data);
          }
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [collectionDefinition, updateFormValues, setLoading, setAuth]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      setLoading(true);
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [collection_definition_id, setLoading, setAuth]);

  const onCreateAttribute = useCallback((objTypeId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const createBody = {
      type: objTypeId,
    };
    setLoading(true);
    return createDynamicTableRow(collectionDefinition.collection_definition_id, createBody)
      .then(({ data }) => {
        if (objTypeId <= 0 && !updatedTypeRouting.default && !(data.type in updatedTypeRouting)) {
          const createRoutingBody = {
            object_type_id: data.type,
            from_section_id: section?.section_id,
          };
          createCollectionRouting(createRoutingBody)
            .then(({ data: newRouting }) => {
              setUpdatedTypeRouting((oriTypeRouting) => ({
                ...oriTypeRouting,
                ...newRouting,
              }));
            })
            .catch((err) => {
              handleErrorResponse(err, setAuth);
            });
        }
        const objectType = objectTypes.find(({ object_type_id }) => object_type_id === Number(data.type));
        if (!objectType) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi(true);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, section, objectTypes, updatedTypeRouting, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  const onEditAttribute = useCallback((selectedId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const selectedRow = rowData.find(({ id }) => id === selectedId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    setLoading(true);
    return Promise.all([
      setUserObjectCache(collectionDefinition.collection_definition_id, selectedId),
      (dynamicData?.redirect_collection_definition_id && dynamicData?.redirect_object_id) ? (
        setUserObjectCache(dynamicData.redirect_collection_definition_id, dynamicData.redirect_object_id)
      ) : null,
    ])
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, rowData, setLoading, setAuth]);

  const onDeleteAttribute = useCallback((datumId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const selectedRow = rowData.find(({ id }) => id === datumId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    setLoading(true);
    return deleteDynamicTableRow(collectionDefinition.collection_definition_id, datumId)
      .then(() => {
        if (dynamicData?.redirect_collection_definition_id && !dynamicData?.redirect_object_id) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi(true);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, rowData, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  const onCopyAttribute = useCallback((datumId) => {
    if (!collectionDefinition) {
      return Promise.resolve();
    }
    const selectedRow = rowData.find(({ id }) => id === datumId);
    const dynamicData = getDynamicData(selectedRow, collectionDefinition.attributes);
    return setCopiedDynamicObj({
      id: datumId,
      collection_definition_id: collectionDefinition.collection_definition_id,
      is_collection: dynamicData?.redirect_collection_definition_id && !dynamicData?.redirect_object_id,
    });
  }, [rowData, collectionDefinition, setCopiedDynamicObj]);

  const onPasteAttribute = useCallback(() => {
    if (!copiedDynamicObj) {
      return Promise.resolve();
    }
    const copyRowBody = {
      from_section_id: section?.section_id,
      destination_collection_definition_id: collectionDefinition?.collection_definition_id,
    };
    setLoading(true);
    return copyDynamicTableRow(copiedDynamicObj.collection_definition_id, copiedDynamicObj.id, copyRowBody)
      .then(({ data }) => {
        if (!updatedTypeRouting.default && !(data.type in updatedTypeRouting)) {
          fetchSectionProps(section, !auth.user)
            .then((updatedSectionProps) => {
              setUpdatedTypeRouting(updatedSectionProps.type_routing);
            })
            .catch((err) => {
              handleErrorResponse(err, setAuth);
            });
        }
        if (copiedDynamicObj.is_collection) {
          fetchObjectTypesFromApi();
        }
        return fetchDataFromApi(true);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [copiedDynamicObj, collectionDefinition, updatedTypeRouting, section, auth.user, fetchDataFromApi, fetchObjectTypesFromApi, setLoading, setAuth]);

  useEffect(() => {
    setUpdatedTypeRouting(type_routing);
  }, [type_routing]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      fetchDataFromApi();
    }
  }, [collectionDefinition, fetchDataFromApi]);

  const parsedRowData = useMemo(() => parseData(rowData, collectionDefinition?.attributes), [rowData, collectionDefinition?.attributes]);

  useEffect(() => {
    if (dataLoaded && !formInitialized) {
      const formValues = getFormValues(parsedRowData, objectTypes);
      setInitialValues(formValues);
      setFormInitialized(true);
    }
  }, [dataLoaded, formInitialized, parsedRowData, objectTypes]);

  const onSubmit = useCallback((values) => {
    setLoading(true);
    return Promise.all(
      (parsedRowData || []).map((parsedRowDatum) => {
        const newValues = {};
        if (nameAttribute) {
          newValues[nameAttribute.attribute_id] = values[`${parsedRowDatum.id}-name`];
        }
        if (valueAttribute) {
          newValues[valueAttribute.attribute_id] = values[`${parsedRowDatum.id}-value`];
        }
        if (systemOrderAttribute) {
          newValues[systemOrderAttribute.attribute_id] = values[`${parsedRowDatum.id}-__system_order__`];
        }
        mandatoryAttributeNames.forEach((attributeName) => {
          const attribute = (collectionDefinition?.attributes || []).find(({ name }) => name === attributeName);
          if (attribute) {
            newValues[attribute.attribute_id] = values[`${parsedRowDatum.id}-${attributeName}`];
          }
        });
        const updateBody = {
          json_short_data: JSON.stringify(newValues),
        };
        return updateDynamicTableRow(collectionDefinition?.collection_definition_id, parsedRowDatum.id, updateBody);
      }),
    ).then(() => {
      alert('Update Successfuly');
      setDataLoaded(false);
      setFormInitialized(false);
      return fetchDataFromApi();
    })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [collectionDefinition, parsedRowData, nameAttribute, valueAttribute, systemOrderAttribute, fetchDataFromApi, setLoading, setAuth]);

  const objectTypeOptions = useMemo(() => (objectTypes || []).reduce((options, objectType) => {
    const { object_type_id, name, redirect, user_created } = objectType;
    if (redirect && !user_created) {
      return [...options, {
        label: name,
        value: object_type_id,
      }];
    }
    return options;
  }, []), [objectTypes]);

  return (
    <MKBox>
      {!isUuid(collection_definition_id) && (
        <MKTypography color="error">Invalid Collection Definition</MKTypography>
      )}
      {!disable_title && (
        <MKBox display="flex" justifyContent="space-between" alignItems="center" py={1}>
          <MKTypography variant="h5">
            {collectionDefinition?.name}
          </MKTypography>
        </MKBox>
      )}
      <MKBox display="flex" flexDirection="row" mb={(addable || copyable) ? 1 : 0}>
        {addable && (
          <Button
            size="small"
            variant="gradient"
            color="success"
            onClick={objectTypes.length > 0 ? onOpenMenu : () => onCreateAttribute('default')}
          >
            {add_button_label || 'Add'}
          </Button>
        )}
        {(copyable && !!copiedDynamicObj) && (
          <MKBox ml={1}>
            <Button
              size="small"
              variant="gradient"
              color="secondary"
              onClick={onPasteAttribute}
            >
              {paste_button_label || 'Paste'}
            </Button>
          </MKBox>
        )}
      </MKBox>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
        innerRef={formRef}
        {...props}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
          return (
            <MKBox component="form" role="form" onSubmit={handleSubmit}>
              {(parsedRowData || []).sort(
                (d1, d2) => get(d1, '__system_order__') - get(d2, '__system_order__'),
              ).map((parsedRowDatum) => {
                let objectType = objectTypes.find(({ object_type_id }) => object_type_id === Number(parsedRowDatum.type));
                if (objectType?.redirect) {
                  objectType = objectTypes.find(({ object_type_id }) => object_type_id === objectType.object_type_id - 1);
                }
                if (!objectType) {
                  return null;
                }
                const attributeNameFieldKey = `${parsedRowDatum.id}-name`;
                const attributeValueFieldKey = `${parsedRowDatum.id}-value`;
                const attributeSystemOrderFieldKey = `${parsedRowDatum.id}-__system_order__`;
                const fieldConfig = fields_config[values[attributeNameFieldKey]];

                const renderValueField = () => {
                  const { object_type_id, user_created } = objectType || {};
                  if (user_created) {
                    return (
                      <MKBox display="flex" justifyContent="flex-end">
                        <MKTypography variant="body2">
                          Collection ID :&nbsp;&nbsp;
                          <Button
                            variant="gradient"
                            color="secondary"
                            size="small"
                            to={updatedTypeRouting[parsedRowDatum.type] || updatedTypeRouting?.default}
                            onClick={() => onEditAttribute(parsedRowDatum.id)}
                          >
                            {parsedRowDatum.redirect_collection_definition_id}
                            <ArrowForwardIcon sx={{ ml: 1 }} />
                          </Button>
                        </MKTypography>
                      </MKBox>
                    );
                  }

                  let inputType = INPUT_TYPE_TEXTAREA;
                  let inputLabel = 'Value';
                  if ([3, 4].includes(object_type_id)) {
                    inputType = INPUT_TYPE_NUMBER;
                  } else if ([5, 6].includes(object_type_id)) {
                    inputType = INPUT_TYPE_BOOLEAN;
                  } else if ([15, 16].includes(object_type_id)) {
                    inputType = INPUT_TYPE_DATETIME;
                  } else if ([7, 8].includes(object_type_id)) {
                    inputType = INPUT_TYPE_IMAGE;
                    inputLabel = 'Image File';
                  } else if ([9, 10].includes(object_type_id)) {
                    inputType = INPUT_TYPE_VIDEO;
                    inputLabel = 'Video File';
                  } else if ([11, 12].includes(object_type_id)) {
                    inputType = INPUT_TYPE_FILE;
                    inputLabel = 'Document File';
                  } else if ([13, 14].includes(object_type_id)) {
                    inputType = INPUT_TYPE_AUDIO;
                    inputLabel = 'Audio File';
                  } else if ([17, 18].includes(object_type_id)) {
                    inputType = INPUT_TYPE_IMAGE;
                    inputLabel = 'Media File';
                  } else if ([19, 20].includes(object_type_id)) {
                    inputType = INPUT_TYPE_RICH_TEXT;
                  }
                  return (
                    <InputField
                      name={attributeValueFieldKey}
                      value={values[attributeValueFieldKey]}
                      label={inputLabel}
                      type={inputType}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      button_text="Upload File"
                      {...([17, 18].includes(object_type_id) ? {
                        accept_file_types: ['image/*', 'video/*'],
                      } : {})}
                      {...fieldConfig}
                    />
                  );
                };
                return (
                  <MKBox p={2} mb={2} borderRadius="lg" bgColor="white" shadow="lg">
                    <MKBox display="flex" flexDirection="row" justifyContent="space-between">
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        {!!systemOrderAttribute && (
                          <MKBox width={60} mr={1}>
                            <InputField
                              name={attributeSystemOrderFieldKey}
                              label={<NumbersIcon fontSize="small" />}
                              type={INPUT_TYPE_NUMBER}
                              size="small"
                              value={values[attributeSystemOrderFieldKey]}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </MKBox>
                        )}
                        <MKTypography>{objectType.user_created ? 'Collection' : (objectType.name || '')}</MKTypography>
                      </MKBox>
                      <MKBox display="flex" flexDirection="row">
                        {copyable && (
                          <MKBox sx={{ mr: 1 }}>
                            <Button
                              size="small"
                              variant="gradient"
                              color="dark"
                              onClick={() => onCopyAttribute(parsedRowDatum.id)}
                            >
                              {copy_button_label || 'Copy'}
                            </Button>
                          </MKBox>
                        )}
                        {deletable && (
                          <Button
                            size="small"
                            variant="gradient"
                            color="error"
                            onClick={() => onDeleteAttribute(parsedRowDatum.id)}
                          >
                            {delete_button_label || 'Delete'}
                          </Button>
                        )}
                      </MKBox>
                    </MKBox>
                    {!!nameAttribute && (
                      <MKBox mt={2}>
                        <InputField
                          name={attributeNameFieldKey}
                          label="Name"
                          value={values[attributeNameFieldKey]}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={objectType.user_created}
                        />
                      </MKBox>
                    )}
                    {mandatoryAttributeNames.map((attributeName, idx) => {
                      const attributeFieldKey = `${parsedRowDatum.id}-${attributeName}`;
                      const attribute = (collectionDefinition?.attributes || []).find(({ name }) => name === attributeName);
                      return attribute ? (
                        <MKBox key={idx} mt={2}>
                          <InputField
                            name={attributeFieldKey}
                            label={startCase(attributeName)}
                            value={values[attributeFieldKey]}
                            type={attributeName === 'description' ? INPUT_TYPE_TEXTAREA : INPUT_TYPE_TEXT}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </MKBox>
                      ) : null;
                    })}
                    <MKBox mt={2}>
                      {!!valueAttribute && renderValueField()}
                    </MKBox>
                  </MKBox>
                );
              })}
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={6} xl={4}>
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                    &nbsp;
                  </MKTypography>
                  <MKBox display="flex">
                    <MKBox display="flex" flex={1}>
                      <Button
                        onClick={onClickCancel}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                      >
                        {dirty ? 'Cancel' : 'Back'}
                      </Button>
                    </MKBox>
                    <MKBox display="flex" flex={1} ml={2}>
                      <Button
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={isSubmitting || !dirty}
                      >
                        Save
                      </Button>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          );
        }}
      </Formik>
      <AnchorMenu
        items={objectTypeOptions}
        onClickItem={onCreateAttribute}
        anchor={menuAnchor}
        setAnchor={setMenuAnchor}
      />
    </MKBox>
  );
};

DynamicTableAttributeViewSection.propTypes = {
  collection_definition_id: PropTypes.string,
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  copyable: PropTypes.bool,
  type_routing: PropTypes.object,
  fields_config: PropTypes.object,
  add_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  copy_button_label: PropTypes.string,
  paste_button_label: PropTypes.string,
  disable_title: PropTypes.bool,
  section: PropTypes.object,
  setLoading: PropTypes.func,
};

export default withLoading(DynamicTableAttributeViewSection);
