import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import InputField, { INPUT_TYPE_BOOLEAN, INPUT_TYPE_NUMBER, INPUT_TYPE_TEXT, INPUT_TYPE_TEXTAREA } from 'components/InputField';

const EditAttributeField = ({ attribute, value, setFieldValue, handleChange, handleBlur, disabled, ...props }) => {
  const { attribute_id, name, data_type } = attribute || {};
  let inputType = null;
  switch (data_type) {
    case 1: // BOOLEAN
      inputType = INPUT_TYPE_BOOLEAN;
      break;
    case 2: // NUMBER
      inputType = INPUT_TYPE_NUMBER;
      break;
    case 4: // TEXT
      inputType = INPUT_TYPE_TEXT;
      break;
    case 8: // JSON
      inputType = INPUT_TYPE_TEXTAREA;
      break;
    default:
      return null;
  }
  return (
    <InputField
      name={attribute_id}
      label={startCase(name)}
      value={value}
      type={inputType}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      disabled={disabled}
      {...props}
    />
  );
};

EditAttributeField.propTypes = {
  attribute: PropTypes.shape({
    attribute_id: PropTypes.string,
    name: PropTypes.string,
    data_type: PropTypes.number,
  }),
  value: PropTypes.any,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

EditAttributeField.defaultProps = {
  attribute: null,
  value: null,
  setFieldValue: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  disabled: false,
};

export default EditAttributeField;
