import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Image from 'components/Image';
import { DrawerMenuItem } from './DrawerMenuItem';

const DEFAULT_MENU_WIDTH = 260;
const DEFAULT_MENU_HEADER_HEIGHT = 100;

const DrawerMenu = ({ menu, ...props }) => {
  const { primary_color, secondary_color, font_color, container_props, item_container_props, active_item_container_props, item_img_props, active_item_img_props, item_label_props, active_item_label_props, menu_node } = menu;
  const { label, img_url, img_props, container_props: header_container_props, label_props, menu_items } = menu_node || {};

  const colorPalette = {
    bgColor: secondary_color,
    fontColor: font_color,
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        'width': DEFAULT_MENU_WIDTH,
        'flexShrink': 0,
        '& .MuiDrawer-paper': {
          width: DEFAULT_MENU_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: primary_color,
          ...JSON.parse(container_props || '{}'),
        },
      }}
      {...props}
    >
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        bgColor={secondary_color}
        sx={{
          height: DEFAULT_MENU_HEADER_HEIGHT,
          px: '1rem',
        }}
        {...JSON.parse(header_container_props || '{}')}
      >
        {img_url && (
          <Image
            src={img_url}
            alt={label}
            width="3.5rem"
            style={{ marginRight: 20 }}
            {...JSON.parse(img_props || '{}')}
          />
        )}
        {label && (
          <MKTypography
            variant="h5"
            color={font_color}
            {...JSON.parse(label_props || '{}')}
          >
            {label}
          </MKTypography>
        )}
      </MKBox>
      <Divider sx={{ m: 0 }} />
      <List>
        {(menu_items || []).map((menuItem, i) => {
          return (
            <DrawerMenuItem
              key={`${menuItem.menu_item_id}-${i}`}
              menuItem={menuItem}
              colorPalette={colorPalette}
              globalProps={{
                container_props: item_container_props,
                active_container_props: active_item_container_props,
                img_props: item_img_props,
                active_img_props: active_item_img_props,
                label_props: item_label_props,
                active_label_props: active_item_label_props,
              }}
            />
          );
        })}
      </List>
    </Drawer>
  );
};

DrawerMenu.propTypes = {
  menu: PropTypes.shape({
    display_name: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    font_color: PropTypes.string,
    container_props: PropTypes.string,
    item_container_props: PropTypes.string,
    active_item_container_props: PropTypes.string,
    item_img_props: PropTypes.string,
    active_item_img_props: PropTypes.string,
    item_label_props: PropTypes.string,
    active_item_label_props: PropTypes.string,
    menu_node: PropTypes.shape({
      menu_items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
          img_url: PropTypes.string,
          img_props: PropTypes.string,
          label_props: PropTypes.string,
          container_props: PropTypes.string,
          menu_items: PropTypes.array,
        }),
      ),
    }),
  }),
};

DrawerMenu.defaultProps = {
  menu: {},
};

export default DrawerMenu;
