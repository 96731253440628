import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';

const Select = ({ label, value, onChange, options, invalid, ...props }) => {
  const selectedOption = (options || []).find((option) => option.value === value);
  return (
    <FormControl fullWidth>
      <InputLabel error={invalid}>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={(['number', 'boolean'].includes(typeof selectedOption?.value)) ? selectedOption?.value : (selectedOption?.value || '')}
        onChange={(e) => onChange(e.target.value)}
        error={invalid}
        fullWidth
        {...props}
      >
        {(options || []).map((option, i) => {
          const { label: optionLabel, value: optionValue } = option;
          return (
            <MenuItem key={`${i}-${optionValue}`} value={optionValue}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};

Select.defaultProps = {
  label: null,
  value: null,
  onChange: () => {},
  invalid: false,
  options: [],
};

export default Select;
