import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import MKBox from 'components/MaterialKit/MKBox';

const SearchBox = ({ inputLabel, inputStyles, searchParamsName, value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange(event.target.value ? event.target.value : null);
  };

  return (
    <MKBox {...props}>
      <TextField
        type="text"
        value={value}
        placeholder={inputLabel}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ fontSize: '24px' }}>
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            ...inputStyles,
          },
        }}
      />
    </MKBox>
  );
};

SearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputLabel: PropTypes.string,
  inputStyles: PropTypes.object,
  searchParamsName: PropTypes.string,
};

export default SearchBox;
