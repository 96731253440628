import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getCollectionDefinition } from 'api/collection_definitions';
import { createDynamicTableRow, getDynamicTableRows, setUserObjectCache } from 'api/sections';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import CollectionCreator from 'components/CollectionCreator';

const CollectionCreatorSection = ({ text, collection_definition_id, on_create_attributes, submit_button_props, back_button_props, navigate_to, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [formAttributes, setFormAttributes] = useState([]);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        }).catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  const onSubmit = useCallback((values) => {
    const createBody = {
      json_short_data: JSON.stringify(values),
    };
    return createDynamicTableRow(collection_definition_id, createBody)
      .then(({ data }) => {
        if (!collectionDefinition) {
          return Promise.resolve();
        }
        const dynamicData = getDynamicData(data, collectionDefinition.attributes);
        return Promise.all([
          setUserObjectCache(collectionDefinition.collection_definition_id, data?.id),
          (dynamicData?.redirect_collection_definition_id && dynamicData?.redirect_object_id) ? (
            setUserObjectCache(dynamicData.redirect_collection_definition_id, dynamicData.redirect_object_id)
          ) : null,
        ])
          .then(() => {
            navigate(navigate_to || '/');
          })
          .catch((err) => {
            handleErrorResponse(err, setAuth);
          });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition, collection_definition_id, navigate, navigate_to, setAuth]);

  const fetchDynamicTableRowDataFromApi = useCallback((dynamicTableId, fieldAttributeId) => {
    if (dynamicTableId) {
      const params = {
        $expand: 'attributes',
        $orderby: 'createddate desc',
      };
      return getDynamicTableRows(dynamicTableId, params)
        .then(({ data }) => {
          const result = data.map((item) => {
            const fieldJsonShortData = item.json_short_data;
            if (fieldJsonShortData) {
              try {
                const parsedData = JSON.parse(fieldJsonShortData);
                const value = item.id;
                const label = parsedData[fieldAttributeId];
                return {
                  value,
                  label,
                };
              } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
              }
            }
            return null;
          });
          return result;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth]);

  const fetchSelectOptions = useCallback((attribute) => {
    if (!attribute.select) {
      return Promise.resolve(attribute);
    }
    return fetchDynamicTableRowDataFromApi(attribute.select.id, attribute.select.field_attribute)
      .then((options) => {
        return {
          options,
        };
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
        return null;
      });
  }, [fetchDynamicTableRowDataFromApi, setAuth]);

  const validCreateAttributes = useMemo(() => {
    if (!on_create_attributes) {
      return Promise.resolve([]);
    }
    if (!collectionDefinition?.attributes) {
      return Promise.resolve([]);
    }
    return Promise.all(
      (on_create_attributes || []).map(({ attribute, ...rest }) => {
        const matchingAttribute = collectionDefinition?.attributes.find(({ name }) => name === attribute);
        if (matchingAttribute) {
          if (rest.select) {
            return fetchSelectOptions({ attribute, ...rest })
              .then((options) => {
                return {
                  ...matchingAttribute,
                  ...rest,
                  ...options,
                };
              })
              .catch((err) => {
                handleErrorResponse(err, setAuth);
              });
          } return { ...matchingAttribute, ...rest };
        }
        return null;
      }),
    );
  }, [fetchSelectOptions, on_create_attributes, collectionDefinition?.attributes, setAuth]);

  useEffect(() => {
    validCreateAttributes
      .then((attributes) => {
        setFormAttributes(attributes);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [validCreateAttributes, setAuth]);

  if (!formAttributes) {
    return null;
  }

  return (
    <CollectionCreator
      text={text}
      onSubmit={onSubmit}
      formAttributes={formAttributes}
      submitButtonProps={submit_button_props}
      backButtonProps={back_button_props}
      navigateTo={navigate_to}
      {...props}
    />
  );
};

CollectionCreatorSection.propTypes = {
  text: PropTypes.string,
  collection_definition_id: PropTypes.string,
  on_create_attributes: PropTypes.array,
  submit_button_props: PropTypes.object,
  back_button_props: PropTypes.object,
  navigate_to: PropTypes.string,
};

export default CollectionCreatorSection;
