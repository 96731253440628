import axios from 'axios';
import get from 'lodash/get';
import split from 'lodash/split';
import * as sha1 from 'js-sha1';

export const fetchFileDataFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    axios.get(url, { responseType: 'blob' })
      .then(({ data: blob }) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      })
      .catch(reject);
  });
};

export const getFileUrl = (dataUrl, filename) => {
  const fileBase64 = get((split(dataUrl, 'base64,')), 1) || '';
  const fileType = dataUrl.match(/data:(.*);base64,/)[1];
  const fileExt = fileType.split('/')[1];
  const finalFilename = filename || `${new Date().valueOf()}_${window.btoa(Math.random().toString()).substr(10, 8)}.${fileExt}`;
  const fileUrl = `Content-Type: ${fileType}\r\nContent-Disposition: attachment;filename="${encodeURI(finalFilename)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${fileBase64}`;
  return fileUrl;
};

export const getFileData = (file, useBase64) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fr = new FileReader();
      fr.onloadend = () => {
        const filename = (file.name).replace(/\s|-/g, '_');
        const fileData = {
          file_url: useBase64 ? getFileUrl(reader.result, filename) : file,
          file_name: filename,
          file_size: file.size,
          sha1_hash: sha1(fr.result),
        };
        resolve(fileData);
      };
      fr.onerror = () => {
        reject(fr.error);
      };
      fr.readAsArrayBuffer(file);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};
