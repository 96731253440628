import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NumbersIcon from '@mui/icons-material/Numbers';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { handleErrorResponse } from 'utils/general';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import { sendApiRequest } from 'api/general';
import { dynamicSort } from 'utils/sort';
import MKTypography from 'components/MaterialKit/MKTypography';
import MultipleSelect from 'components/MultipleSelect';
import SearchBox from 'components/SearchBox';
import { useSearchParams } from 'react-router-dom';

const DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER = '__system_order__';

const AMSFilterSearchTable = ({
  target_url,
  pass_id,
  target_id_parameter,
  method,
  params,
  expand_fields,
  display_attributes,
  columns_config,
  type_routing,

  // add rest of props here
  use_add_button,
  add_button_path,
  add_button_label,
  add_button_props,
  use_delete_button,
  delete_button_path,
  delete_button_label,
  delete_button_props,
  use_search_box,
  search_box_placeholder,
  search_box_props,
  search_box_input_props,
  use_select_component,
  select_box_label,
  select_box_props,
  select_box_parameter,
  use_search_button,
  search_button_label,
  search_button_props,
  search_box_parameter,

  ...props
}) => {
  const [rowData, setRowData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER);
  const [sortDirection, setSortDirection] = useState('asc');
  const { auth, setAuth } = useAuth();

  const [searchField, setSearchField] = useState('');
  const [selectFields, setSelectFields] = useState([]);

  const fetchDataFromApi = useCallback((request_params) => {
    sendApiRequest(target_url, method, request_params, 'bearer')
      .then(({ data, headers }) => {
        setTotalDataCount(data.length);
        const contentRange = get(headers, 'content-range');
        const dataCount = Number(contentRange.split('/')[1]);
        setTotalDataCount(dataCount);

        const dynamicFieldValueData = data.map((datum) => ({
          ...getDynamicData(datum, display_attributes),
          ...datum,
        }));

        const sortedRowData = dynamicFieldValueData.sort(dynamicSort(sortBy || 'createddate', sortDirection));
        setRowData(sortedRowData.slice(
          rowsPerPage * currentPage,
          rowsPerPage * (currentPage + 1),
        ));
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [currentPage, display_attributes, method, rowsPerPage, setAuth, sortBy, sortDirection, target_url]);

  const prepareParams = useCallback(() => {
    const expandable = expand_fields.map((str) => str.replace(/\s/g, '')).join(',');
    const expandable_fields = expandable ? { $expand: `${expandable}` } : {};
    const request_params = {
      ...params,
      ...expandable_fields,
    };

    if (use_search_box) {
      if (searchField) {
        request_params[search_box_parameter] = searchField;
      }
    }

    if (use_select_component) {
      if (selectFields.length > 0) {
        request_params[select_box_parameter] = selectFields.join(',');
      }
    }

    if (target_id_parameter) {
      request_params[target_id_parameter] = pass_id;
    }

    return fetchDataFromApi(request_params);
  }, [expand_fields, fetchDataFromApi, params, pass_id, searchField, search_box_parameter, selectFields, select_box_parameter, target_id_parameter, use_search_box, use_select_component]);

  const onClickSort = useCallback((headerName) => {
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, []);

  const renderSystemOrderComponent = useCallback((datum, attribute) => (
    <TextInput
      size="small"
      type="number"
      value={get(datum, attribute.name)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  ), []);

  useEffect(() => {
    if (target_url) {
      fetchDataFromApi();
    }
  }, [target_url, fetchDataFromApi]);

  const displayAttributes = useMemo(() => {
    if ((display_attributes || []).length > 0) {
      const res = display_attributes.map((attr) => {
        const { name } = attr;
        let { data_type } = attr;
        if (data_type === undefined) {
          data_type = 4;
        }
        const propertyNamesArray = name.split('.');
        if (propertyNamesArray.length > 1) {
          return {
            name: propertyNamesArray[propertyNamesArray.length - 1],
            nested: propertyNamesArray[0],
            data_type,
          };
        }
        return {
          name,
          data_type,
        };
      });
      return res;
    }
  }, [display_attributes]);

  const columns = useMemo(() => {
    if (!displayAttributes || displayAttributes.length === 0) {
      return [];
    }
    return displayAttributes.map((attribute) => {
      const { name, data_type } = attribute;
      let dataFormatter;
      switch (data_type) {
        case 1:
          dataFormatter = (v) => (v ? 'Yes' : 'No');
          break;
        case 4:
          dataFormatter = (v) => ((v || '').length > 50 ? `${v.substring(0, 50)}...` : v);
          break;
        case 8:
          dataFormatter = (v) => JSON.stringify(v);
          break;
        default:
          break;
      }
      return {
        field: name,
        ...(name === DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER ? {
          label: <NumbersIcon fontSize="small" />,
          renderContent: (datum) => renderSystemOrderComponent(datum, attribute),
          width: '0',
        } : {}),
        ...(dataFormatter ? {
          formatter: dataFormatter,
        } : {}),
        ...(columns_config && columns_config[name] ? columns_config[name] : {}),
      };
    });
  }, [displayAttributes, columns_config, renderSystemOrderComponent]);

  const parsedRowData = useMemo(() => rowData.map((rowDatum) => {
    const { ...restDatum } = rowDatum;
    const parsedRowDatum = displayAttributes.reduce((result, attribute) => {
      const { name, nested } = attribute;
      if (nested) {
        const nestedValue = get(rowDatum, nested);
        return {
          ...result,
          [name]: nestedValue ? get(nestedValue, name) : '',
        };
      }
      return {
        ...result,
        [name]: get(rowDatum, name),
      };
    }, {});
    return {
      ...parsedRowDatum,
      ...restDatum,
    };
  }), [rowData, displayAttributes]);

  const handleSearchFieldChange = useCallback((value) => {
    setSearchField(value);
  }, []);

  const handleSelectFieldChange = useCallback((values) => {
    setSelectFields(values);
  }, []);

  const handleFilterApply = useCallback(() => {
    prepareParams();
  }, [prepareParams]);

  return (
    <MKBox>
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="1rem"
      >
        { use_add_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { console.log('Add'); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...add_button_props}
          >
            {`${add_button_label}`}
          </Button>
        )}
        { use_delete_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { console.log('Delete'); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...delete_button_props}
          >
            {`${delete_button_label}`}
          </Button>
        )}
        {use_select_component && (
        <MultipleSelect
          items={[{ value: 'completed', label: 'Completed' }, { value: 'Pending', label: 'Pending' }]}
          values={selectFields}
          onSelectChange={handleSelectFieldChange}
          showSelected
          openToTop={false}
          searchParamsName={select_box_label}
          {...select_box_props}
        />
        )}
        {use_search_box && (
        <SearchBox
          inputLabel={search_box_placeholder}
          inputStyles={search_box_input_props}
          onChange={handleSearchFieldChange}
          value={searchField}
          searchParamsName="search"
          {...search_box_props}
        />
        )}
        { use_search_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { handleFilterApply(); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...search_button_props}
          >
            {`${search_button_label}`}
          </Button>
        ) }
      </MKBox>
      <DataTable
        columns={columns}
        data={parsedRowData}
        typeRouting={{}}
        totalCount={totalDataCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={setRowsPerPage}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

AMSFilterSearchTable.propTypes = {
  target_url: PropTypes.string,
  target_id_parameter: PropTypes.string,
  pass_id: PropTypes.string,
  method: PropTypes.string,
  params: PropTypes.object,
  expand_fields: PropTypes.arrayOf(PropTypes.string),
  display_attributes: PropTypes.arrayOf(PropTypes.Object),
  columns_config: PropTypes.object,
  type_routing: PropTypes.object,
  disable_pagination: PropTypes.bool,

  use_add_button: PropTypes.bool,
  add_button_path: PropTypes.string,
  add_button_label: PropTypes.string,
  add_button_props: PropTypes.object,

  use_delete_button: PropTypes.bool,
  delete_button_path: PropTypes.string,
  delete_button_label: PropTypes.string,
  delete_button_props: PropTypes.object,

  use_search_button: PropTypes.bool,
  search_button_label: PropTypes.string,
  search_button_props: PropTypes.object,

  use_search_box: PropTypes.bool,
  search_box_placeholder: PropTypes.string,
  search_box_props: PropTypes.object,
  search_box_input_props: PropTypes.object,
  search_box_parameter: PropTypes.string,

  use_select_component: PropTypes.bool,
  select_box_label: PropTypes.string,
  select_box_props: PropTypes.object,
  select_box_parameter: PropTypes.string,

};

export default AMSFilterSearchTable;
