import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';

const menuIemTypeOptions = [
  { label: 'Node', value: 1 },
  { label: 'Item', value: 2 },
];

const EditMenuItemDependencyForm = ({ menuItemDependency, onSave, onCancel, ...props }) => {
  const initialValues = useMemo(() => ({
    label: menuItemDependency?.menu_item?.label || '',
    label_props: menuItemDependency?.menu_item?.label_props || '{}',
    active_label_props: menuItemDependency?.menu_item?.active_label_props || '{}',
    type: menuItemDependency?.menu_item?.type || 2,
    path: menuItemDependency?.menu_item?.path || '',
    img_url: menuItemDependency?.menu_item?.img_url || '',
    active_img_url: menuItemDependency?.menu_item?.active_img_url || '',
    img_props: menuItemDependency?.menu_item?.img_props || '{}',
    active_img_props: menuItemDependency?.menu_item?.active_img_props || '{}',
    container_props: menuItemDependency?.menu_item?.container_props || '{}',
    active_container_props: menuItemDependency?.menu_item?.active_container_props || '{}',
    sequence: menuItemDependency?.sequence || '',
    disabled: menuItemDependency?.menu_item?.disabled || false,
  }), [menuItemDependency]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="label"
                  label="Label"
                  value={values.label || ''}
                  onChange={handleChange('label')}
                  onBlur={handleBlur('label')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="label_props"
                  label="Label Props"
                  value={values.label_props || '{}'}
                  onChange={handleChange('label_props')}
                  onBlur={handleBlur('label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_label_props"
                  label="Active Label Props"
                  value={values.active_label_props || '{}'}
                  onChange={handleChange('active_label_props')}
                  onBlur={handleBlur('active_label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="img_url"
                  label="Image Url"
                  value={values.img_url || ''}
                  onChange={handleChange('img_url')}
                  onBlur={handleBlur('img_url')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="img_props"
                  label="Image Props"
                  value={values.img_props || '{}'}
                  onChange={handleChange('img_props')}
                  onBlur={handleBlur('img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_img_url"
                  label="Active Image Url"
                  value={values.active_img_url || ''}
                  onChange={handleChange('active_img_url')}
                  onBlur={handleBlur('active_img_url')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_img_props"
                  label="Active Image Props"
                  value={values.active_img_props || '{}'}
                  onChange={handleChange('active_img_props')}
                  onBlur={handleBlur('active_img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Type"
                  value={values.type}
                  onChange={(v) => setFieldValue('type', v)}
                  options={menuIemTypeOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  name="sequence"
                  label="Sequence"
                  type="number"
                  value={values.sequence || ''}
                  onChange={handleChange('sequence')}
                  onBlur={handleBlur('sequence')}
                  fullWidth
                />
              </Grid>
              {values.type !== 1 && (
                <Grid item xs={12}>
                  <MKInput
                    name="path"
                    label="Path"
                    value={values.path || ''}
                    onChange={handleChange('path')}
                    onBlur={handleBlur('path')}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Select
                  label="Disabled"
                  value={values.disabled}
                  onChange={(v) => setFieldValue('disabled', v)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  invalid={touched.disabled && !!errors.disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="container_props"
                  label="Container Props"
                  value={values.container_props || '{}'}
                  onChange={handleChange('container_props')}
                  onBlur={handleBlur('container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="active_container_props"
                  label="Active Container Props"
                  value={values.active_container_props || '{}'}
                  onChange={handleChange('active_container_props')}
                  onBlur={handleBlur('active_container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      disabled={isSubmitting || !dirty}
                      fullWidth
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : 'Save'}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditMenuItemDependencyForm.propTypes = {
  menuItemDependency: PropTypes.shape({
    menu_item: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.number,
      path: PropTypes.string,
      img_url: PropTypes.string,
      active_img_url: PropTypes.string,
      img_props: PropTypes.string,
      active_img_props: PropTypes.string,
      label_props: PropTypes.string,
      active_label_props: PropTypes.string,
      container_props: PropTypes.string,
      active_container_props: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    sequence: PropTypes.number,
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditMenuItemDependencyForm.defaultProps = {
  menuItemDependency: {},
  onSave: () => {},
  onCancel: () => {},
};

export default EditMenuItemDependencyForm;
