import PropTypes from 'prop-types';
import Logs from 'components/Logs';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicTableRows } from 'api/sections';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';

const LogsSection = ({
  collection_definition_id,
  log_title,
  export_button_title,
  export_button_props,
  use_date_filter,
  use_error_filter,
  use_user_filter,
  exportable,
  extensions,
  sort_by,
  font_size,
  editable,
  onPressEdit,
  ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [content, setContent] = useState([]);
  const { auth, setAuth } = useAuth();

  const fetchDataFromApi = useCallback(() => {
    if (collectionDefinition?.collection_definition_id) {
      const params = {
        $orderby: sort_by || 'createddate',
      };
      return getDynamicTableRows(collectionDefinition.collection_definition_id, params)
        .then(({ data }) => {
          const dynamicFieldValueData = data.map((datum) => ({
            ...getDynamicData(datum, collectionDefinition?.attributes),
            ...datum,
          }));
          setContent(dynamicFieldValueData);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collectionDefinition, setAuth, sort_by]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      fetchDataFromApi();
    }
  }, [collectionDefinition, fetchDataFromApi]);

  const fontSizeValue = useMemo(() => (Number.isNaN(Number(font_size)) ? font_size : Number(font_size)), [
    font_size,
  ]);

  return (
    <Logs
      logTitle={log_title || 'Logs'}
      exportButtonTitle={export_button_title || 'Export'}
      exportButtonProps={export_button_props}
      useDateFilter={use_date_filter}
      useErrorFilter={use_error_filter}
      useUserFilter={use_user_filter}
      exportable={exportable}
      extensions={extensions}
      sortBy={sort_by}
      content={content}
      fontSize={fontSizeValue}
      {...props}
    />
  );
};

LogsSection.propTypes = {
  collection_definition_id: PropTypes.string,
  log_title: PropTypes.string,
  export_button_title: PropTypes.string,
  export_button_props: PropTypes.object,
  use_user_filter: PropTypes.bool,
  use_error_filter: PropTypes.bool,
  use_date_filter: PropTypes.bool,
  exportable: PropTypes.bool,
  extensions: PropTypes.array,
  sort_by: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default LogsSection;
