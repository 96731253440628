import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField from 'components/InputField';
import startCase from 'lodash/startCase';
import { useAuth } from 'contexts/auth';

const CollectionCreator = ({ text, formAttributes, onSubmit, backButtonProps, submitButtonProps, navigateTo, ...props }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const initialValues = useMemo(() => {
    return formAttributes.reduce((values, attribute) => {
      const newValues = { ...values };
      newValues[attribute.attribute_id] = values[attribute.attribute_id] || '';
      if (attribute.operator_field) {
        newValues[attribute.attribute_id] = auth.user.display_name || 'Unknown operator';
      }
      return newValues;
    }, {});
  }, [formAttributes, auth.user.display_name]);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <MKBox {...props}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleChange, setFieldValue, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
          return (
            <MKBox component="form" role="form" onSubmit={handleSubmit}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="text"
                  size="medium"
                  fontSize="16px"
                  borderRadius={backButtonProps?.borderRadius || '0px'}
                  onClick={onClickCancel}
                  iconOnly={false}
                  disabled={false}
                  fullWidth
                  circular={false}
                  color={backButtonProps?.color || 'primary'}
                  {...backButtonProps}
                >
                  {dirty ? 'Cancel' : 'Back'}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="medium"
                  fontSize="16px"
                  borderRadius={submitButtonProps?.borderRadius || '0px'}
                  iconOnly={false}
                  disabled={isSubmitting || !dirty}
                  fullWidth
                  circular={false}
                  color={submitButtonProps?.color || 'primary'}
                  {...submitButtonProps}
                >
                  Create
                </Button>
              </MKBox>
              {formAttributes.sort((a1, a2) => a1.sequence - a2.sequence).map((attribute) => {
                if (attribute.operator_field) {
                  return (
                    <MKBox mt={2} key={attribute.attribute_id}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            Operator:
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {auth.user.display_name || 'Unknown operator'}
                          </MKTypography>
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.date) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="datetime"
                            button_text="Upload File"
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.options) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="select"
                            options={attribute.options}
                            button_text="Upload File"
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.file_input) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="file"
                            button_text="Browse Folder"
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                return (
                  <MKBox mt={2}>
                    {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="text"
                            button_text="Upload File"
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </MKBox>
                );
              })}
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={6} xl={4}>
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                        &nbsp;
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
          );
        }}
      </Formik>
    </MKBox>
  );
};

CollectionCreator.propTypes = {
  text: PropTypes.string,
  formAttributes: PropTypes.array,
  onSubmit: PropTypes.func,
  navigateTo: PropTypes.string,
  backButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
};

export default CollectionCreator;
